import { SbLinkSearchParams } from '@api-client/src/api/getLinks';
import { Context, useContentFactory } from '@vue-storefront/core';
import { isAxiosError } from 'axios';

export const search = async (context: Context, params: SbLinkSearchParams) => {
  try {
    const { data } = await context.$sb.api.getLinks(params);

    return data;
  } catch (err) {
    if (isAxiosError(err)) {
      throw err.response?.data || err;
    }

    throw err;
  }
};

const useLinks = useContentFactory({
  search,
});

export default useLinks;
