import { SbAllContentParams } from '@api-client/src/api/getAllContent';
import { Context, useContentFactory } from '@vue-storefront/core';
import { isAxiosError } from 'axios';

export { SbAllContentParams };

export const search = async (context: Context, params: SbAllContentParams) => {
  try {
    const { data } = await context.$sb.api.getAllContent(params);

    return data;
  } catch (err) {
    if (isAxiosError(err)) {
      throw err.response?.data || err;
    }

    throw err;
  }
};

const useAllContent = useContentFactory({ search });

export default useAllContent;
