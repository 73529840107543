import { SbAllContentParams } from '@api-client/src/api/getAllContent/index';
import mapSbCommonParams from '@api-client/src/shared/mapSbCommonParams';

export const mapSbContentParams = (contentParams: SbAllContentParams): Record<string, unknown> => {
  const { startsWith, filterQuery, sortBy, searchTerm, bySlugs } = contentParams;

  const params: Record<string, unknown> = mapSbCommonParams(contentParams);

  if (filterQuery) {
    params.filter_query = filterQuery;
  }

  if (startsWith) {
    params.starts_with = startsWith;
  }

  if (sortBy) {
    params.sort_by = sortBy;
  }

  if (searchTerm) {
    params.search_term = searchTerm;
  }

  if (bySlugs) {
    params.by_slugs = bySlugs;
  }

  return params;
};
